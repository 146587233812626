export type CompanyConfigurationDetails = {
    name: string; // directly from the schema
    type: string; // directly from the schema
    description: string; // should be clear to someone who doesn't have much context about the feature
    exposedToUser: boolean; // whether we allow users to configure it directly in the pallet UI
};

export const companyConfigurationDetails: CompanyConfigurationDetails[] = [
    {
        name: "sameDayDispatchCellHeader",
        type: "SameDayDispatchCellHeader",
        description:
            "Controls the format of the title of the cell in the Same Day dispatch page. Either shows the BOL or service level in the title.",
        exposedToUser: false,
    },
    {
        name: "sendSummaryEmailWithIncompleteRoutes",
        type: "Boolean",
        description:
            "At the end of the day, sends summary email with incomplete routes. Only available in prod.",
        exposedToUser: true,
    },
    {
        name: "startingInvoiceOrdinalNumber",
        type: "Int?",
        description: "Starting number for invoice ordinals, defaults to 1",
        exposedToUser: true,
    },
    {
        name: "defaultStandardStopType",
        type: "StandardStopType",
        description: "Default type for standard stops, defaults to RESIDENTIAL",
        exposedToUser: false,
    },
    {
        name: "defaultFuelSurchargeBillingMethod",
        type: "FuelBillingMethod",
        description:
            "Default billing method for fuel surcharges, defaults to PERCENTAGE",
        exposedToUser: true,
    },
    {
        name: "defaultFuelSurcharge",
        type: "Decimal?",
        description: "Default fuel surcharge rate",
        exposedToUser: true,
    },
    {
        name: "defaultDimFactor",
        type: "Int?",
        description:
            "Default dimensional factor for volume to weight conversion, defaults to 250",
        exposedToUser: true,
    },
    {
        name: "defaultFuelSettlementPercentageRate",
        type: "Decimal?",
        description: "Default fuel settlement percentage rate, defaults to 100",
        exposedToUser: true,
    },
    {
        name: "defaultRouteStartTime",
        type: "DateTime?",
        description: "Default start time for routes",
        exposedToUser: true,
    },
    {
        name: "defaultRouteLoadTimeInMinutes",
        type: "Int?",
        description: "Default load time for routes in minutes",
        exposedToUser: true,
    },
    {
        name: "defaultRouteUnloadTimeInMinutes",
        type: "Int?",
        description: "Default unload time for routes in minutes",
        exposedToUser: true,
    },
    {
        name: "enableConsolidatedOrders",
        type: "Boolean",
        description:
            "(Currently unused) Feature where shipments on orders can be consolidated during billing if they have the same route + address",
        exposedToUser: false,
    },
    {
        name: "showChargesOnCustomerPortal",
        type: "Boolean",
        description: "Displays charges on the customer portal",
        exposedToUser: false,
    },
    {
        name: "textNextCustomerForAppointments",
        type: "Boolean",
        description:
            "Sends automatic texts to the contact person for the next stop on the route, which is determined by the stop number",
        exposedToUser: true,
    },
    {
        name: "invoicePdfLogoUrl",
        type: "String?",
        description: "URL for the invoice PDF logo",
        exposedToUser: false,
    },
    {
        name: "showManuallyConfirmedByDefault",
        type: "Boolean?",
        description: "Unused config",
        exposedToUser: false,
    },
    {
        name: "allowDriverToSelectNextStop",
        type: "Boolean?",
        description: "Allows drivers to select their next stop",
        exposedToUser: true,
    },
    {
        name: "showHelpersColumnInDispatch",
        type: "Boolean?",
        description: "Unused config",
        exposedToUser: false,
    },
    {
        name: "showVehicleInDailyDispatch",
        type: "Boolean?",
        description:
            "Whether vehicles are displayed in the daily dispatch page",
        exposedToUser: false,
    },
    {
        name: "requireReviewForEdiOrderCreation",
        type: "Boolean",
        description:
            "Requires EDI orders to go into review instead of being automatically accepted and created",
        exposedToUser: false,
    },
    {
        name: "autoApplyWaitTimeMinutes",
        type: "Boolean",
        description: "(Theoretically) unused config",
        exposedToUser: false,
    },
    {
        name: "useJournalNumberForInvoice",
        type: "Boolean",
        description: "Uses journal number for invoice identification",
        exposedToUser: true,
    },
    {
        name: "getDriverLocationDataFromMobile",
        type: "Boolean",
        description: "Gets driver location data from mobile",
        exposedToUser: true,
    },
    {
        name: "mobilePollingIntervalInSeconds",
        type: "Int?",
        description: "How often location data will be polled on mobile",
        exposedToUser: true,
    },
    {
        name: "requireDriverToAcknowledgeRouteChange",
        type: "Boolean",
        description:
            "When a stop is added to a route after it’s already started, drivers receive push notifications informing them of this change. If this config is on, the driver can mark the notification as acknowledged in the UI and dispatchers can see the status of this acknowledgement.",
        exposedToUser: true,
    },
    {
        name: "useDriverNumberForDispatch",
        type: "Boolean",
        description:
            "Show the reference number associated with the driver when selecting a driver to assign to a route",
        exposedToUser: false,
    },
    {
        name: "defaultToAccessorial",
        type: "Boolean",
        description: "(Theoretically) unused config",
        exposedToUser: true,
    },
    {
        name: "enablePaymentMethod",
        type: "Boolean",
        description:
            "If there’s a payment method on the order and this config is enabled, displays the payment method on mobile",
        exposedToUser: true,
    },
    {
        name: "driversShouldMarkFreightAsLoaded",
        type: "Boolean",
        description:
            "Drivers can mark each order’s freight as loaded onto their truck before starting the route. If the route is started without all the pieces being loaded, they see a warning message",
        exposedToUser: true,
    },
    {
        name: "allCapsInOrderPage",
        type: "Boolean",
        description: "Displays all text in uppercase on the order page",
        exposedToUser: false,
    },
    {
        name: "orderNamingScheme",
        type: "OrderNamingScheme",
        description:
            "Scheme used for naming orders, defaults to CONTACT_ORDINAL. CONTACT_ORDINAL: EXPE-123, COMPANY_ORDINAL: 000123.",
        exposedToUser: true,
    },
    {
        name: "companyOrdinalStart",
        type: "Int?",
        description: "Starting ordinal number for company.",
        exposedToUser: true,
    },
    {
        name: "minDigitsInCompanyOrdinalOrderName",
        type: "Int?",
        description: "Minimum digits in company ordinal order name.",
        exposedToUser: true,
    },
    {
        name: "restrictEditingOrderName",
        type: "Boolean",
        description: "Restricts editing of order name",
        exposedToUser: true,
    },
    {
        name: "showKilogramsAndCentimeters",
        type: "Boolean",
        description: "Shows weights in kilograms and dimensions in centimeters",
        exposedToUser: false,
    },
    {
        name: "showDriverSettlementInBilling",
        type: "Boolean",
        description:
            "Displays driver settlement section when reviewing charges in Billing Review",
        exposedToUser: true,
    },
    {
        name: "notificationsEnabled",
        type: "Boolean",
        description:
            "Notifications can be viewed by clicking the bell icon. Currently have notifications for email sending and drivers marking stops as attempted.",
        exposedToUser: false,
    },
    {
        name: "requireDimsToFinalizeCharges",
        type: "Boolean",
        description: "Requires dimensions to finalize charges",
        exposedToUser: false,
    },
    {
        name: "addOrdersToOpenInvoices",
        type: "Boolean",
        description:
            "When putting finalized orders on an invoice, automatically add to open invoices for the billing party",
        exposedToUser: true,
    },
    {
        name: "invoiceNotes",
        type: "String?",
        description: "Notes included in invoices.",
        exposedToUser: true,
    },
    {
        name: "autoMatchEodDocumentsToOrders",
        type: "Boolean",
        description: "Unused config",
        exposedToUser: false,
    },
    {
        name: "requirePODPhotoAndName",
        type: "Boolean",
        description: "Requires photo and name for proof of delivery",
        exposedToUser: true,
    },
    {
        name: "requirePODPhotoAndNameForPickups",
        type: "Boolean",
        description:
            "Requires photo and name for proof of delivery for pickups",
        exposedToUser: true,
    },
    {
        name: "packageWarehouseLocationsEnabled",
        type: "Boolean",
        description:
            "Enables setting warehouse locations for the pieces that our customers receive/pick in the WMS STS product",
        exposedToUser: false,
    },
    {
        name: "wmsEnabled",
        type: "Boolean",
        description: "Warehouse Management System (WMS) enabled",
        exposedToUser: false,
    },
    {
        name: "wmsStorageOrdersEnabled",
        type: "Boolean",
        description: "Enables WMS receipts",
        exposedToUser: false,
    },
    {
        name: "terminalsEnabled",
        type: "Boolean",
        description: "Terminals feature enabled",
        exposedToUser: false,
    },
    {
        name: "lineHaulEnabled",
        type: "Boolean",
        description: "Line haul feature enabled",
        exposedToUser: false,
    },
    {
        name: "logisticsSectionEnabled",
        type: "Boolean",
        description:
            "Logistics section (with hazmat info) displayed in order page",
        exposedToUser: false,
    },
    {
        name: "fulfillmentTypeEnabled",
        type: "Boolean",
        description:
            "Fulfillment type (shown on the orders page and used for dispatch) is enabled",
        exposedToUser: false,
    },
    {
        name: "orderIdentifierForCoverSheet",
        type: "OrderIdentifier",
        description: "Order identifier used in cover sheet barcode",
        exposedToUser: true,
    },
    {
        name: "wmsShortTermStorageEnabled",
        type: "Boolean",
        description: "WMS short term storage is enabled",
        exposedToUser: false,
    },
    {
        name: "wmsAutoMarkOnHand",
        type: "Boolean",
        description: "Automatically mark WMS containers and items on hand",
        exposedToUser: false,
    },
    {
        name: "enableDigitalSignatureFlow",
        type: "Boolean",
        description: "Allow drivers to e-sign delivery and pickup receipts",
        exposedToUser: false,
    },
    {
        name: "includeHyphensInInvoiceName",
        type: "Boolean",
        description:
            "Include hyphens in invoice name (e.g. OMNI-INV-123 vs OMNIINV123)",
        exposedToUser: true,
    },
    {
        name: "includeINVInInvoiceName",
        type: "Boolean",
        description:
            "Include INV in invoice name (e.g. OMNI-INV-123 vs OMNI-123)",
        exposedToUser: true,
    },
    {
        name: "appointmentSchedulingSlotHours",
        type: "Int",
        description:
            "The duration of appointments when using the appointment scheduling (reservation) system",
        exposedToUser: false,
    },
    {
        name: "appointmentSchedulingLeadingBufferHours",
        type: "Int",
        description:
            "The minimum buffer time, in hours, needed from the current moment to the earliest appointment time allowed when using the reservation system",
        exposedToUser: false,
    },
    {
        name: "appointmentSchedulingCutoffDays",
        type: "Int",
        description:
            "The number of days from the current moment that the reservation system will allow appointments to be scheduled",
        exposedToUser: false,
    },
    {
        name: "appointmentChangeCutoffHours",
        type: "Int",
        description:
            "The minimum duration needed before the start of an appointment to allow selecting or requesting to reschedule an appointment",
        exposedToUser: false,
    },
    {
        name: "createRecurringOrdersInAdvanceHours",
        type: "Int",
        description:
            "The number of hours in advance of a day for which there are recurring orders configured that we need to create orders",
        exposedToUser: false,
    },
];
