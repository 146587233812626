import * as dayjs from 'dayjs';
import { isNil } from 'lodash';
import slugify from 'slugify';

export const isNilOrEmptyString = (
  obj: unknown,
): obj is null | undefined | '' => {
  return isNil(obj) || (typeof obj === 'string' && obj.length === 0);
};

export const isNotNilOrEmptyString = (
  obj: unknown,
): obj is Exclude<string, ''> => {
  return !isNil(obj) && typeof obj === 'string' && obj.length > 0;
};

/**
 * If the value is null, undefined, or '', returns undefined. Otherwise, applies
 * the provided function to the value.
 */
export const mapUndefinedOrEmptyString = <TypeA, TypeB>(
  fn: (value: TypeA) => TypeB,
  value: TypeA | null | undefined | '',
): TypeB | undefined => {
  if (isNilOrEmptyString(value)) {
    return undefined;
  }

  return fn(value);
};

/**
 * If the value is null, undefined, or '', returns null. Otherwise, applies the
 * provided function to the value.
 */
export const mapNullOrEmptyString = <TypeA, TypeB>(
  fn: (value: TypeA) => TypeB,
  value: TypeA | null | undefined | '',
): TypeB | null => {
  if (isNilOrEmptyString(value)) {
    return null;
  }

  return fn(value);
};

const DATE_TIME_STRING_FORMAT = 'MM/DD hh:mm a';

export const transformDateTimeToDateTimeString = ({
  dateTime,
  timeZone,
}: {
  dateTime: Date | null | undefined;
  /** If this is undefined, defaults to the local system time zone */
  timeZone: string | undefined;
}) => {
  if (isNil(dateTime)) {
    return '-';
  }
  if (isNilOrEmptyString(timeZone)) {
    return dayjs(dateTime).format(DATE_TIME_STRING_FORMAT);
  }
  return dayjs(dateTime).tz(timeZone).format(DATE_TIME_STRING_FORMAT);
};

// Vendoring this in here because we can't import ESM modules yet.
// https://github.com/sindresorhus/filename-reserved-regex/blob/ce517280005241b44852ff14bbf4d2384768c95c/index.js
// eslint-disable-next-line no-control-regex
const filenameReservedRegex = /[<>:"/\\|?*\u0000-\u001F]/g;

/**
 * Clean up a name for filename safety.
 */
export const filenamify = (str: string) =>
  slugify(str, {
    remove: filenameReservedRegex,
  });
